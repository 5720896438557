import ImportExportIcon from "@mui/icons-material/ImportExport";

import React, { useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import { TextField, Tooltip } from "@mui/material";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import styles from "./ImportExportButton.module.css";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  selectDeckBuilder,
  DeckCard,
  setWorkingDeck,
} from "src/features/deckBuilder/deckBuilderSlice";
import { SearchContext } from "src/provider/searchProvider";
import { copyTextToClipboard } from "./../util/copy";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DigimonData } from "/home/node/dev/dcd2/src/data/cardInfo";
import ImportSlider from "./ImportSlider";
import { useSnackbar } from "notistack";
import { CustomDeck } from "./CustomDeck";
import { selectCore } from "src/features/coreuiStore/coreSlice";
const importPlaceholder = `//Paste Deck here
// Format:
// Quantity Name Id
// Name is optional. Quantity should be 1-4 (except cards like eosmon)
// Each card can only be declared once
// Import will always pick the regular art card`;

const cardTypeOrder: { [key: string]: number } = {
  "Digi-Egg": 0,
  Digimon: 1,
  Tamer: 2,
  Option: 3,
};

const TtsExportText = () => (
  <>
    <b>
      Export your deck to Tabletop Simulator. Compatible with and Zaffy's Mod
      and Cake's Mod
    </b>
    <br />
    Cake's Mod{" "}
    <a
      target="_blank"
      rel="noopener"
      href="https://steamcommunity.com/sharedfiles/filedetails/?id=2330775492"
    >
      Link to SteamMod{" "}
    </a>
    <br />
  </>
);
export const ImportExportButton: React.FC<{
  title?: JSX.Element | string;
  content?: JSX.Element;
  tooltip?: string;
}> = ({ title, content, tooltip }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);

  const [importMode, setImportMode] = React.useState<boolean>(false);
  const [mode, setMode] = React.useState<string>("text");
  const [textValue, setTextValue] = React.useState("");

  const theme = useTheme();
  const { baseCards, ciBiMap, setCiBiMap } = useContext(SearchContext);

  const { workingDeck, eggCount, mainCount } =
    useAppSelector(selectDeckBuilder);

  const dispatch = useAppDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);
  };

  var placeholder;
  if (importMode) {
    switch (mode) {
      case "text":
        placeholder = importPlaceholder;
    }
  } else {
  }

  // no workingDeck changes
  useEffect(() => {
    if (importMode) {
      switch (mode) {
        case "text":
          setTextValue("");
          break;
        case "tts":
          setMode("text");
          break;
      }
    } else {
      switch (mode) {
        case "text":
          //handleExportText(workingDeck, baseCards, setTextValue);
          break;
      }
    }
  }, [mode, importMode]);

  // with workingDeck Changes
  useEffect(() => {
    if (importMode) {
      switch (mode) {
        case "text":
        //setTextValue("");
        // break;
      }
    } else {
      switch (mode) {
        case "text":
          handleExportText(workingDeck, baseCards, setTextValue);
          break;
        case "tts":
          handleExportTts(workingDeck, baseCards, setTextValue);
      }
    }
  }, [mode, importMode, workingDeck]);

  //const startImport = startImport(ciBiMap, baseCards, setCiBiMap, setTextValue);
  const showTextField = !(!importMode && mode == "image");
  const { theme: themeColor } = useAppSelector(selectCore);
  const dark = themeColor === "dark" ? "dark" : "";
  return (
    <>
      <Tooltip title={tooltip ?? ""}>
        <Button onClick={handleClickOpen}>
          <ImportExportIcon />
          Import/Export
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        fullScreen={useMediaQuery(theme.breakpoints.down("md"))}
        className={dark}
      >
        <DialogTitle id="alert-dialog-title" className={"dcback"}>
          {importMode ? "Import from..." : "Export to..."}
        </DialogTitle>

        <DialogContent className={"dcback"}>
          <div className={styles.toggleGroups}>
            <ToggleButtonGroup
              value={importMode}
              exclusive
              onChange={(event, newValue) => {
                if (newValue === null) {
                  return;
                }
                setImportMode(newValue);
              }}
              aria-label="import mode"
            >
              <ToggleButton value={true} aria-label="import">
                Import
              </ToggleButton>
              <ToggleButton value={false} aria-label="export">
                Export
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              value={mode}
              exclusive
              onChange={(event, newValue) => {
                if (newValue === null) {
                  return;
                }
                setMode(newValue);
              }}
              aria-label="import mode"
            >
              <ToggleButton value="text" aria-label="import">
                Text
              </ToggleButton>
              <ToggleButton
                value="tts"
                aria-label="export"
                disabled={importMode}
              >
                TableTopSim
              </ToggleButton>
              <ToggleButton
                value="image"
                aria-label="export"
                disabled={importMode}
              >
                Image Export
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <DialogContentText id="alert-dialog-description" className="mt-3">
            {importMode && mode == "text" && (
              <ul>
                <li>Paste text below.</li>
                <li>Drag the left slider below up to 90% to import.</li>
              </ul>
            )}
            {!importMode && mode == "tts" && <TtsExportText />}
          </DialogContentText>
          {!importMode && mode == "image" && (
            <CustomDeck deck={workingDeck} baseCards={baseCards} />
          )}
          {showTextField && (
            <>
              <Button
                variant="outlined"
                className={styles.copybutton}
                onClick={() => {
                  copyTextToClipboard(textValue).then(
                    () => {
                      enqueueSnackbar("Copied to Clipboard.", {
                        autoHideDuration: 2000,
                      });
                    },
                    () => {
                      enqueueSnackbar("Unable to copy.", {
                        variant: "warning",
                        autoHideDuration: 2000,
                      });
                    }
                  );
                }}
              >
                <ContentCopyIcon /> CopyText
              </Button>

              <TextField
                placeholder={placeholder}
                multiline
                fullWidth
                value={textValue}
                variant="filled"
                onChange={handleChange}
                inputProps={{ className: styles.textfield }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {importMode && mode == "text" && (
            <ImportSlider
              disabled={textValue.length == 0}
              onCommit={() => {
                startImport(
                  dispatch,
                  ciBiMap,
                  baseCards,
                  setCiBiMap,
                  setTextValue,
                  enqueueSnackbar
                )(textValue);
              }}
            />
          )}
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportExportButton;
function startImport(
  dispatch: any,
  ciBiMap: Map<string, number[]> | undefined,
  baseCards: DigimonData[],
  setCiBiMap:
    | React.Dispatch<React.SetStateAction<Map<string, number[]> | undefined>>
    | undefined,
  setTextValue: React.Dispatch<React.SetStateAction<string>>,
  enqueueSnackbar: any
) {
  return (tempText: string) => {
    const findMap = ciBiMap ?? CreateCiBiMap(ciBiMap, baseCards, setCiBiMap!);

    console.log("Import");
    var lines = tempText.split("\n");
    var warning = false;
    const unique = new Map<number, number>();
    var response = "//Import Result\n";
    var re =
      /[ \t]*([1-9]\d*)[ \t]+([\S\t ]+)[ \t]+(\w+-\d+)(?:[\t ]+\((\w+)\)){0,1}([\t ]*\/\/\s*[ \t\S]*)*/;

    var commentRe = /^\s*\/\//;
    lines.forEach((line) => {
      if (line.length == 0) {
        response += "\n";
        return;
      }
      if (commentRe.test(line)) {
        //we're a comment
        response += line + "\n";
        return;
      }
      var res = re.exec(line);
      if (res == null) {
        response += "//BAD LINE @: " + line + "\n";
        warning = true;
        return;
      }
      response += line;
      var [capture, qty, name, id, alt] = res;
      var num = Number(qty);
      if (num > 50) {
        response += "//QTY CAP @50";
        warning = true;
        num = 50;
      }
      var baseIds = findMap?.get(id);
      if (baseIds === undefined) {
        response += "//CARD NOT FOUND @: " + line + "\n";
        warning = true;
        return;
      }
      //if (unique[baseId]) {
      //repeated id
      //  response += "//ID REPEATED @: " + line + "\n";
      //  return;
      //}
      //unique[baseId] = true;

      if (alt) {
        // Check if alt exists
        let trimmed = alt.trim();
        let matchAlt = findMap!
          .get(id)!
          .filter((bid) => baseCards[bid].pid == trimmed);
        if (matchAlt.length > 0) {
          response += " //ALT FOUND\n";
          let uniqueVal = unique.get(matchAlt[0]);
          unique.set(matchAlt[0], (uniqueVal ?? 0) + num);
        } else {
          response += " //ALT NOT FOUND - USING NON-ALT\n";
          // add to normal card
          let uniqueVal = unique.get(baseIds[0]);
          unique.set(baseIds[0], (uniqueVal ?? 0) + num);
        }
      } else {
        response += "\n";
        let uniqueVal = unique.get(baseIds[0]);
        unique.set(baseIds[0], (uniqueVal ?? 0) + num);
      }
    });
    var newWorkingDeck: DeckCard[] = [];
    unique.forEach((value, key) => {
      //let baseId = findMap?.get(key)![0] ?? 0;
      let baseId = key;
      newWorkingDeck.push({
        baseId: baseId,
        qty: value,
        gid: baseCards[baseId].gid ?? 0,
      });
    });
    console.log(newWorkingDeck);
    dispatch(setWorkingDeck({ workingDeck: newWorkingDeck, baseCards }));
    setTextValue(response);
    if (warning) {
      enqueueSnackbar("Import completed with warnings.", {
        variant: "warning",
      });
    } else {
      enqueueSnackbar("Import completed.");
    }
  };
}

function CreateCiBiMap(
  ciBiMap: Map<string, number[]> | undefined,
  baseCards: DigimonData[],
  setCiBiMap: React.Dispatch<
    React.SetStateAction<Map<string, number[]> | undefined>
  >
) {
  var newMap;
  if (ciBiMap === undefined || ciBiMap === null) {
    newMap = new Map<string, number[]>();
    for (var i = 0; i < baseCards.length; i++) {
      let card = baseCards[i];
      let val = newMap.get(card.id);
      if (val === undefined) {
        newMap.set(card.id, [card.baseid!]);
      } else if (card.pid || card.notes?.includes("parallel")) {
        newMap.set(card.id, [...val!, card.baseid!]);
      } else {
        newMap.set(card.id, [card.baseid!, ...val]);
      }
    }
    setCiBiMap(newMap);
  }
  return ciBiMap ?? newMap;
}

function handleExportText(
  workingDeck: DeckCard[],
  baseCards: DigimonData[],
  setTextValue: React.Dispatch<React.SetStateAction<string>>
) {
  const sortedWorkingDeck = [...workingDeck].sort((a, b) => {
    //sort by type first
    var aCard = baseCards[a.baseId];
    var bCard = baseCards[b.baseId];
    if (cardTypeOrder[aCard.ctype] - cardTypeOrder[bCard.ctype] == 0) {
      if (aCard.ctype == "Digimon") {
        if ((aCard.lv ?? 100) - (bCard.lv ?? 100) == 0) {
          return aCard.id.localeCompare(bCard.id);
        } else {
          return (aCard.lv ?? 100) - (bCard.lv ?? 100);
        }
      }
      return aCard.id.localeCompare(bCard.id);
    } else {
      return cardTypeOrder[aCard.ctype] - cardTypeOrder[bCard.ctype];
    }
  });
  var longestName: number = 0;
  for (var i = 0; i < sortedWorkingDeck.length; i++) {
    longestName = Math.max(
      baseCards[workingDeck[i].baseId].name.length + 1,
      longestName
    );
  }

  var text = "// Digimon DeckList\n\n";
  if (sortedWorkingDeck.length == 0) {
    text += "// No cards in deck";
  }
  for (var i = 0; i < sortedWorkingDeck.length; i++) {
    let card = baseCards[sortedWorkingDeck[i].baseId];
    text += sortedWorkingDeck[i].qty + " ";
    text += card.name.padEnd(longestName, " ");
    text += card.id;
    if (card.pid!.length > 0) {
      text += ` (${card.pid})`;
    }
    text += "\n";
  }
  setTextValue(text);
}

function handleExportTts(
  workingDeck: DeckCard[],
  baseCards: DigimonData[],
  setTextValue: React.Dispatch<React.SetStateAction<string>>
) {
  const sortedWorkingDeck = [...workingDeck].sort((a, b) => {
    //sort by type first
    var aCard = baseCards[a.baseId];
    var bCard = baseCards[b.baseId];
    if (cardTypeOrder[aCard.ctype] - cardTypeOrder[bCard.ctype] == 0) {
      if (aCard.ctype == "Digimon") {
        if ((aCard.lv ?? 100) - (bCard.lv ?? 100) == 0) {
          return aCard.id.localeCompare(bCard.id);
        } else {
          return (aCard.lv ?? 100) - (bCard.lv ?? 100);
        }
      }
      return aCard.id.localeCompare(bCard.id);
    } else {
      return cardTypeOrder[aCard.ctype] - cardTypeOrder[bCard.ctype];
    }
  });
  var longestName: number = 0;
  for (var i = 0; i < sortedWorkingDeck.length; i++) {
    longestName = Math.max(
      baseCards[workingDeck[i].baseId].name.length + 1,
      longestName
    );
  }

  var text = [`["Exported from https://digimoncard.dev"`];

  for (var i = 0; i < sortedWorkingDeck.length; i++) {
    let card = baseCards[sortedWorkingDeck[i].baseId];
    for (var j = 0; j < sortedWorkingDeck[i].qty; j++) {
      text.push(`"${card.id}"`);
    }
  }

  setTextValue(text.join(",") + "]");
}
