import React from "react";
import styles from "./Stats.module.css";
import { Counts } from "src/features/deckStore/deckSlice";
import { statSync } from "fs";
import { useAppSelector } from "src/app/hooks";
import { selectCore } from "src/features/coreuiStore/coreSlice";

export const toColor = (color: string) => {
  switch (color) {
    case "Blu":
      return "#0998d3";
    case "Yel":
      return "#f9dc04";

    case "Gre":
      return "#01965d";

    case "Whi":
      return "white";

    case "Bla":
      return "#5a5a5a";

    case "Pur":
      return "#8C36B3";

    case "Red":
    default:
      return "#e61030";
  }
};

export const Stats: React.FC<{
  stats: Counts;
  otherStyles?: React.CSSProperties;
}> = React.memo(({ stats, otherStyles }) => {
  const colorKeys = Object.keys(stats.col).sort(
    (a, b) => stats.col[b] - stats.col[a]
  );
  const maxColors = Math.max(...Object.values(stats.col));
  const levelKeys = Object.keys(stats.lv).sort();
  const levels: { [key: string]: number } = { ...stats.lv, "7+": 0 };
  var sevenPlus = 0;

  const { theme } = useAppSelector(selectCore);

  const bgCol = theme === "dark" ? "black" : "white";

  levelKeys
    .filter((key) => parseInt(key) > 6)
    .forEach((key) => {
      sevenPlus = sevenPlus + stats.lv[key];
    });
  levels["7+"] = sevenPlus;
  const maxLevels = Math.max(...Object.values(levels));
  const ctypeKey = ["Egg", "Digi", "Tamer", "Option"];
  const maxDdto = Math.max(...Object.values(stats.ddto));
  return (
    <div className={styles.stats} style={otherStyles}>
      <div className={styles.colors + " colors"}>
        {colorKeys.map((key) => (
          <div key={key}>
            <div
              style={{
                background: `linear-gradient(${bgCol} calc(${
                  100 * (1 - stats.col[key] / maxColors)
                }% ), ${toColor(key)} ${
                  100 * (1 - stats.col[key] / maxColors)
                }%`,
              }}
            >
              {stats.col[key]}
            </div>
            <div>{key}</div>
          </div>
        ))}
      </div>
      <div className={styles.colors + " " + styles.levels + " colors"}>
        {["3", "4", "5", "6", "7+"].map((key) => (
          <div key={key}>
            <div
              style={{
                background: `linear-gradient(${bgCol} calc(${
                  100 * (1 - levels[key] / maxLevels)
                }% ), gray ${100 * (1 - levels[key] / maxLevels)}%`,
              }}
            >
              {levels[key] ?? 0}
            </div>
            <div>Lv{key}</div>
          </div>
        ))}
      </div>
      <div className={styles.colors + " colors"}>
        {["Digi-Egg", "Digimon", "Tamer", "Option"].map((key, i) => (
          <div key={key}>
            <div
              style={{
                background: `linear-gradient(${bgCol} calc(${
                  100 * (1 - stats.ddto[key] / maxDdto)
                }% ), gray ${100 * (1 - stats.ddto[key] / maxDdto)}%`,
              }}
            >
              {stats.ddto[key] ?? 0}
            </div>
            <div>{ctypeKey[i]}</div>
          </div>
        ))}
      </div>
    </div>
  );
});
