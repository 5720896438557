import React from "react";
import { DCDeck } from "src/features/deckStore/deckSlice";
import { Stats, toColor } from "./Stats";
import styles from "./DecklistProfile.module.css";
import { Paper, Chip } from "@mui/material";

export const EmptyDecklistProfile: React.FC<{
  name: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}> = ({ name, onClick }) => {
  var date = new Date();

  return (
    <Paper className={styles.profile} elevation={2} onClick={onClick}>
      <div className={styles.date}>
        {`${date.getMonth() + 1}/${date.getDate()}`}
      </div>
      <div className={styles.name}>{name}</div>
      <div className={styles.empty}>Use a new deck slot.</div>
    </Paper>
  );
};

export const DecklistProfile: React.FC<{
  deck: DCDeck;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  index: number;
}> = ({ deck, onClick, index }) => {
  var date = new Date((deck.s as number) * 1000);
  const maxColor = [...Object.keys(deck.c?.col!), ""].sort((a, b) => {
    return deck.c!.col![b] - deck.c!.col![a];
  })[0];
  const color = toColor(maxColor);
  return (
    <Paper className={styles.profile} elevation={2} onClick={onClick}>
      <Chip label={index} size="small" />
      <div className={styles.date}>
        {`${date.getMonth() + 1}/${date.getDate()}`}
      </div>
      <div className={styles.name}>{deck.n}</div>

      <div
        className={styles.bar}
        style={{
          background: color,
          ...(color == "white" && { border: "1px solid grey" }),
        }}
      ></div>
      <Stats stats={deck.c!} />
    </Paper>
  );
};
