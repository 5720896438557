import {
  CCloseButton,
  COffcanvas,
  COffcanvasBody,
  COffcanvasHeader,
  COffcanvasTitle,
} from "@coreui/react";
import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  addCard,
  removeCard,
  DeckCard,
  selectDeckBuilder,
  setVisible,
  togglePosition,
  toggleSize,
  clearAll,
} from "src/features/deckBuilder/deckBuilderSlice";

import styles from "./BuilderPortal.module.css";
import logo from "src/assets/icons/logo3.png";
import { CardImage } from "./CardImage";
import { SearchContext } from "src/provider/searchProvider";
import { DigimonData } from "src/data/cardInfo";
import { Button, IconButton, Divider, Tooltip } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { SaveButton } from "./SaveButton";
import { ZoomOptions } from "./ZoomOptions";
import { Stats } from "./Stats";
import { DeleteButton } from "./DeleteDialog";
import { setContext } from "src/features/contextMenu/contextSlice";
import { ImportExportButton } from "./ImportExportButton";
import { OverflowTools } from "./OverflowTools";
import PieChartIcon from "@mui/icons-material/PieChart";

export const BuilderPortal: React.FC = () => {
  const { visible, location, size, builderVisible } =
    useAppSelector(selectDeckBuilder);

  const dispatch = useAppDispatch();
  var canvasStyle: React.CSSProperties = { transition: "0.25s" };
  var logoStyle: React.CSSProperties = {};
  if (location == "end") {
    canvasStyle.width = `${builderVisible ? size : 0}vw`;
  } else {
    canvasStyle.height = `${builderVisible ? size : 0}vh`;
    logoStyle.width = "100%";
  }
  if (builderVisible) {
    canvasStyle.visibility = "visible";
  } else {
    canvasStyle.visibility = "collapse";
  }

  return (
    <COffcanvas
      placement={location}
      backdrop={false}
      portal={false}
      style={canvasStyle}
      visible={builderVisible}
      className="coc"
    >
      {<DeckBuilder />}
    </COffcanvas>
  );
};

const Plus: React.FC<{
  deckCard: DeckCard;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ deckCard, onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <AddBoxIcon />
    </IconButton>
  );
};

const Minus: React.FC<{
  deckCard: DeckCard;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ deckCard, onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <IndeterminateCheckBoxIcon />
    </IconButton>
  );
};

const CardStack: React.FC<{
  deckCard: DeckCard;
  baseCards: DigimonData[];
  size: number;
  onRightClick?: React.MouseEventHandler<HTMLDivElement>;
  onLeftClick?: React.MouseEventHandler<HTMLDivElement>;
}> = ({ deckCard, baseCards, size, onRightClick, onLeftClick }) => {
  const dispatch = useAppDispatch();
  const addCards = () => {
    return () => {
      dispatch(addCard({ baseId: deckCard.baseId, baseCards }));
    };
  };
  const removeCards = () => {
    return () => {
      dispatch(removeCard({ baseId: deckCard.baseId, baseCards }));
    };
  };

  return (
    <div className={styles.cardContainer}>
      <div className={styles.qty + " qty"}>
        <Plus deckCard={deckCard} onClick={addCards()} />
        {deckCard.qty}
        <Minus deckCard={deckCard} onClick={removeCards()} />
      </div>
      <CardImage
        onRightClick={onRightClick}
        onLeftClick={onLeftClick}
        data={baseCards[deckCard.baseId]}
        size={size}
        otherStyles={{ margin: "0px" }}
      />
    </div>
  );
};

const DeckBuilder: React.FC = () => {
  const {
    visible,
    location,
    deckCardSize,
    size,
    workingDeck,
    eggCount,
    mainCount,
  } = useAppSelector(selectDeckBuilder);
  const { baseCards } = useContext(SearchContext);
  const dispatch = useAppDispatch();
  const handlePosition = () => {
    dispatch(togglePosition());
  };

  const [showStats, setShowStats] = useState(true);

  const handleSize = () => {
    dispatch(toggleSize());
  };

  const [wdStats, setWdStats] = useState<{
    col: { [key: string]: number };
    lv: { [key: string]: number };
    ddto: { [key: string]: number };
    count: number;
  }>({
    count: 0,
    col: {},
    lv: {},
    ddto: {},
  });

  var logoStyle: React.CSSProperties = {};
  if (location == "end") {
    logoStyle.width = "90%";
  } else {
  }

  useEffect(() => {
    var stats: {
      col: { [key: string]: number };
      lv: { [key: string]: number };
      ddto: { [key: string]: number };
      count: number;
    } = {
      count: 0,
      col: {},
      lv: {},
      ddto: {},
    };
    for (var i = 0; i < workingDeck.length; i++) {
      let deckCard = workingDeck[i];
      let card = baseCards[deckCard.baseId];
      stats["count"] = stats["count"] + deckCard.qty;
      stats.ddto[card.ctype] = (stats.ddto[card.ctype] ?? 0) + deckCard.qty;
      card.color.split(" ").forEach((color) => {
        stats.col[color.slice(0, 3)] =
          (stats.col[color.slice(0, 3)] || 0) + deckCard.qty;
      });
      if (card.ctype == "Digimon" || card.ctype == "Digi-Egg") {
        stats.lv[card.lv!.toString()] =
          (stats.lv[card.lv!.toString()] || 0) + deckCard.qty;
      }
    }

    setWdStats(stats);
  }, [workingDeck]);

  const handleRightClick: (
    baseId: number
  ) => React.MouseEventHandler<HTMLDivElement> = (baseId) => {
    return (event) => {
      event.preventDefault();
      dispatch(
        setContext({
          baseId: baseId,
          visible: true,
          location: { x: event.clientX, y: event.clientY },
        })
      );
    };
  };

  const tools = [
    <ImportExportButton />, // 0
    <Tooltip title="Position">
      <Button onClick={handlePosition}>
        {location == "end" ? "BOTTOM" : "SIDE"}
      </Button>
    </Tooltip>, // 1
    <SaveButton stats={wdStats} />, // 2
    <ZoomOptions mode={"builder"} step={0.2} />, // 3
    <Button onClick={() => setShowStats(!showStats)}>
      <PieChartIcon /> Stats
    </Button>,
  ];
  var showTools: boolean[] = [];
  if (window.innerWidth > 600) {
    showTools = [true, true, true, true];
  } else if (window.innerWidth > 445) {
    showTools = [true, true, true, false];
  } else if (window.innerWidth > 400) {
    showTools = [true, false, true, false];
  } else {
    showTools = [false, false, true, false];
  }

  return (
    <div
      className={styles.buildContainer}
      style={{ flexDirection: location == "end" ? "row" : "column" }}
    >
      <div
        className={
          (location == "end" ? styles.buildLeft : styles.buildUp) + " buildGrid"
        }
      />
      <div className={"buildGrid " + styles.buildGrid}>
        <div className={styles.logo}>
          <img src={logo} style={logoStyle} draggable={false} />
        </div>

        <div className={styles.tools}>
          <div
            className={
              eggCount > 5 ? `${styles.count} ${styles.red}` : styles.count
            }
          >
            <b>{eggCount}</b>/<sub>5</sub> Eggs
          </div>
          <Divider orientation="vertical" flexItem />
          <div
            className={
              mainCount > 50 ? `${styles.count} ${styles.red}` : styles.count
            }
          >
            <b>{mainCount}</b>/<sub>50</sub> Cards
          </div>

          <Divider orientation="vertical" flexItem />
          <DeleteButton
            title="Clear Builder?"
            tooltip="Delete Deck"
            agree={() => {
              dispatch(clearAll());
            }}
          />
          {tools
            .filter((val, i) => showTools[i])
            .map((val, i) => (
              <React.Fragment key={i}>
                <Divider orientation="vertical" flexItem />
                {val}
              </React.Fragment>
            ))}
          <OverflowTools items={tools.filter((val, i) => !showTools[i])} />
          <BigScreenButton
            onClick={handleSize}
            size={size}
            location={location}
          />
        </div>
        <div className={styles.bottom}>
          <div style={{ width: "100%" }}>
            {showStats && (
              <Stats stats={wdStats} otherStyles={{ maxWidth: 800 }} />
            )}
          </div>
          {workingDeck.map((val, i) => (
            <CardStack
              key={val.baseId}
              deckCard={val}
              baseCards={baseCards}
              size={deckCardSize}
              onLeftClick={handleRightClick(val.baseId)}
              onRightClick={handleRightClick(val.baseId)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
const BigScreenButton: React.FC<{
  onClick: () => void;
  size: number;
  location: "bottom" | "end";
}> = ({ onClick, size, location }) => {
  const style =
    location == "bottom"
      ? size == 80
        ? styles.arrowDown
        : styles.arrowUp
      : size == 80
      ? styles.arrowRight
      : styles.arrowLeft;
  return (
    <Button onClick={onClick}>
      <DoubleArrowIcon className={style} />
      {"BIGSCREEN"}
    </Button>
  );
};
