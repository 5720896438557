import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { type } from "os";
import { RootState, AppThunk } from "../../app/store";
import Joyride, {
  // ACTIONS,
  // CallBackProps,
  // EVENTS,
  // STATUS,
  Step,
} from "react-joyride";

export interface HelperState {
  run: boolean;
  steps: string;
  stepIndex: number;
}

const initialState: HelperState = {
  run: false,
  steps: "",
  stepIndex: 0,
};

export const frontPageHelper: Step[] = [
  {
    content: <>Try typing "agu" here. This is the main search bar.</>,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: ".searchBar",
  },
  {
    content: <div>Try typing "agumon".</div>,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    target: ".searchBar",
  },
];

export const helpers = {
  frontPageHelper,
};

export const helperSlice = createSlice({
  name: "helper",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setHelper: (state, action: PayloadAction<{ [k: string]: any }>) => {
      console.log("setting state");
      return (state = { ...state, ...action.payload });
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(incrementAsync.pending, (state) => {
  //         state.status = "loading";
  //       })
  //       .addCase(incrementAsync.fulfilled, (state, action) => {
  //         state.status = "idle";
  //         state.value += action.payload;
  //       });
  //   },
});

export const { setHelper } = helperSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectCount = (state: RootState) => state.counter.value;
export const selectHelper = (state: RootState) => state.helper;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default helperSlice.reducer;
