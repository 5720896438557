//https://gist.github.com/manix/a2160e7084c5c7fef5431af359fb342e

const ctypeOrder: { [key: string]: number } = {
  "Digi-Egg": 0,
  Digimon: 1,
  Tamer: 2,
  Option: 3,
};

const colorOrder: (key: string) => number = (key: string) => {
  switch (key) {
    case "red":
      return 0;
    case "blue":
      return 1;
    case "blue green":
      return 1;
    case "yellow":
      return 2;
    case "yellow purple":
      return 2;
    case "green":
      return 3;
    case "green blue":
      return 3;
    case "black":
      return 4;
    case "purple":
      return 5;
    case "purple yellow":
      return 5;
    case "white":
      return 6;
    default:
      return 10;
  }
};

export function fieldSorter(fields: string[], order: boolean[]) {
  var i,
    l = fields.length;

  return function (a: { [key: string]: any }, b: { [key: string]: any }) {
    for (i = 0; i < l; i++) {
      var o = fields[i];
      if (o === "ctype") {
        if (ctypeOrder[a[o] as string] > ctypeOrder[b[o] as string])
          return order[i] ? 1 : -1;
        if (ctypeOrder[a[o] as string] < ctypeOrder[b[o] as string])
          return order[i] ? -1 : 1;
      } else if (o === "color") {
        if (colorOrder(a[o] as string) > colorOrder(b[o] as string))
          return order[i] ? 1 : -1;
        if (colorOrder(a[o] as string) < colorOrder(b[o] as string))
          return order[i] ? -1 : 1;
      }
      if (a[o] > b[o]) return order[i] ? 1 : -1;
      if (a[o] < b[o]) return order[i] ? -1 : 1;
    }
    return 0;
  };
}

export function deepFieldSorter(
  deep: string,
  fields: string[],
  order: boolean[]
) {
  var i,
    l = fields.length;

  return function (
    a: { [key: string]: { [key: string]: any } },
    b: { [key: string]: { [key: string]: any } }
  ) {
    for (i = 0; i < l; i++) {
      var o = fields[i];
      if (a[deep][o] > b[deep][o]) return order[i] ? 1 : -1;
      if (a[deep][o] < b[deep][o]) return order[i] ? -1 : 1;
    }
    return 0;
  };
}
