import { DigimonData } from "./cardInfo";

const attr = [
  "",
  "Data",
  "Vaccine",
  "Virus",
  "Free",
  "Variable",
  "Unknown",
  "",
];

const cardtype = ["", "Digi-Egg", "Digimon", "Tamer", "Option"];

export const color = [
  "", // 0
  "Red", // 1
  "", //
  "Blue", // 3
  "Yellow", // 4
  "Black", // 5
  "Green", // 6
  "Purple", // 7
  "White", // 8
  "", // 9
  "Rainbow", // 10
  "Yellow Purple", //11
  "Purple Yellow", //12
  "Blue Green", //13
  "Green Blue", // 14
  "Yellow Blue", // 15
  "Red Blue", // 16
  "Red Yellow", // 17
  "Green Red", // 18
  "Blue Red", // 19
  "Blue Black",
  "Blue Yellow", // 21
  "Black Red",
  "Red Black",
];

const stage = [
  "Baby",
  "In Training",
  "Rookie",
  "Champion",
  "Ultimate",
  "Mega",
  "Undefined/Unknown",
  "",
  "Hybrid",
  "Armor",
];

export const rare = [
  "Common",
  "Uncommon",
  "Rare",
  "Super Rare",
  "Promo",
  "Secret Rare",
];

const keepNull = (val: any, finalVal: any) => {
  return val == undefined || val == null ? null : finalVal;
};

export const autokeywords = [...attr, ...color, ...rare, ...stage, ...cardtype];
export function dataMapper(
  d: {
    [property: string]: string | null;
  }
  //i: number
): DigimonData {
  const temp = {
    id: d.cardid as string,
    name: d.name as string,
    ctype: cardtype[Number(d.cardtype)],
    color: keepNull(d.color, color[Number(d.color)]),
    ecostcolor: keepNull(d.ecostcolor, color[Number(d.ecostcolor)]),
    ecostcolor2: keepNull(d.ecostcolor2, color[Number(d.ecostcolor2)]),
    elvfrom: keepNull(d.elvfrom, Number(d.elvfrom)),
    elvfrom2: keepNull(d.elvfrom2, Number(d.elvfrom2)),
    cost: keepNull(d.cost, Number(d.cost)),
    ecost: keepNull(d.ecost, Number(d.ecost)),
    ecost2: keepNull(d.ecost2, Number(d.ecost2)),
    lv: keepNull(d.level, Number(d.level)),
    dp: keepNull(d.dp, Number(d.dp)),
    dtype: d.dtype,
    attr: keepNull(d.attr, attr[Number(d.attr)]),
    form: keepNull(d.stage, stage[Number(d.stage)]),
    rare: rare[Number(d.rare)],
    ieffect: d.sourceeffect,
    meffect: d.maineffect,
    seceffect: d.securityeffect,
    notes: d.notes,
    imageUrl: d.imageUrl,
    language: Number(d.language),
    gid: Number(d.gid),
    _id: Number(d.gid),
    //baseid: i,
    artist: d.artist,
    src: d.src,
    intl: d.intl == "1",
    price: Number(d.price) / 100,
    shop: d.shop,
    any: "",
    pid: d.p || "",
  };

  //temp.json = JSON.stringify(temp).toLocaleLowerCase();
  // @ts-ignore
  return temp;
}

export function dataMapperLowerCase(d: DigimonData): DigimonData {
  const temp = {
    ...d,
    id: d.id?.toLowerCase() as string,
    name: d.name?.toLowerCase() as string,
    ctype: d.ctype?.toLowerCase(),
    color: d.color?.toLowerCase() as string,
    ecostcolor: d.ecostcolor?.toLowerCase() as string,
    ecostcolor2: d.ecostcolor2?.toLowerCase() as string,
    //elvfrom: d.elvfrom,
    //elvfrom2: keepNull(d.elvfrom2, Number(d.elvfrom2)),
    //cost: keepNull(d.cost, Number(d.cost)),
    //ecost: keepNull(d.ecost, Number(d.ecost)),
    //ecost2: keepNull(d.ecost2, Number(d.ecost2)),
    //lv: keepNull(d.level, Number(d.level)),
    //dp: keepNull(d.dp, Number(d.dp)),
    dtype: d.dtype?.toLowerCase(),
    attr: d.attr?.toLowerCase(),
    form: d.form?.toLowerCase(),
    rare: (d.rare as string)?.toLowerCase(),
    ieffect: d.ieffect?.toLowerCase(),
    meffect: d.meffect?.toLowerCase(),
    seceffect: d.seceffect?.toLowerCase(),
    notes: d.notes?.toLowerCase(),
    //language: Number(d.language),
    //gid: Number(d.gid),
    //_id: Number(d.gid),
    artist: d.artist?.toLowerCase(),
    src: d.src?.toLowerCase(),
    //intl: d.intl == "1",
    //price: Number(d.price) / 100,
    pid: d.pid?.toLowerCase(),
  };

  temp.any = JSON.stringify(temp).toLocaleLowerCase();
  // @ts-ignore
  return temp;
}
