import { usePopper } from "react-popper";
import React, { useContext, Fragment } from "react";

import cssStyle from "./ContextMenu.module.css";
import { CardImage } from "./CardImage";
import { SearchContext } from "src/provider/searchProvider";
import { DigimonData } from "src/data/cardInfo";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAppSelector, useAppDispatch } from "src/app/hooks";
import {
  selectContext,
  setVisible,
} from "src/features/contextMenu/contextSlice";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { styled } from "@mui/material/styles";

import Popover, { PopoverProps } from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";
import { selectCore } from "src/features/coreuiStore/coreSlice";

const visibleProps = {
  name: "Name",
  ctype: "Card Type",
  color: "Color",
  lv: "Level",
  cost: "Play Cost",
  ecost: "Digivolve Cost",
  ecost2: "Digivolve Cost-2",
  ecostcolor: "Digivolve Color",
  ecostcolor2: "Digivolve Color-2",
  elvfrom: "Digivolve From",
  elvfrom2: "Digivolve From-2",
  dp: "Power",
  dtype: "Type",
  attr: "Attribute",
  form: "Form",
  rare: "Rarity",
  //notes: "Notes",
  artist: "Artist",
};

const visibleLongProps = {
  src: "Source",
  meffect: "Effect",
  ieffect: "Inherited Effect",
  seceffect: "Security Effect",
};

const Desc = ({ text }: { text: any }) => {
  const texts = text ? text : "";
  return (
    <>
      {texts.split("\n").map((i: any) => (
        <Fragment key={i}>
          {i}
          <br />
        </Fragment>
      ))}
    </>
  );
};

const StyledMenu = styled((props: PopoverProps) => (
  <Popover
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    background: "transparent",
  },
}));

export const ContextMenu = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const { baseCards } = useContext(SearchContext);

  const { theme: themeColor } = useAppSelector(selectCore);
  const dark = themeColor === "dark" ? "dark" : "";
  const dispatch = useAppDispatch();

  const { baseId, visible, location } = useAppSelector(selectContext);

  if (baseCards.length == 0 || visible == false) {
    return <></>;
  }

  const card = baseCards[baseId];

  const visiblePropsKeys = Object.keys(visibleProps);
  const visibleLongPropsKeys = Object.keys(visibleLongProps);
  const vprops: { key: string; value: any }[] = Object.keys(card)
    .filter(
      (val) =>
        visiblePropsKeys.includes(val) &&
        // @ts-ignore
        card[val] != null &&
        // @ts-ignore
        ((card[val].length != undefined && card[val].length > 0) ||
          // @ts-ignore
          typeof card[val] == "number")
    )
    .map((val) => {
      // @ts-ignore
      return { key: visibleProps[val], value: card[val] };
    });
  const vlongprops = Object.keys(card)
    // @ts-ignore
    .filter((val) => visibleLongPropsKeys.includes(val) && card[val] != null)
    .map((val) => {
      // @ts-ignore
      return { key: visibleLongProps[val], value: card[val] };
    })
    .sort((a, b) => a.key.localeCompare(b.key));

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    dispatch(setVisible(false));
    e.preventDefault();
  };

  const contextClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const classes = (e.target as Element).classList;
    if (
      classes.contains(cssStyle.value) ||
      classes.contains(cssStyle.valueLong) ||
      (e.target as Element).nodeName == "A"
    ) {
    } else {
      console.info(e.target);
      e.preventDefault();
    }

    return true;
  };

  return (
    <>
      {fullScreen ? (
        <StyledMenu
          TransitionComponent={Fade}
          keepMounted
          //anchorEl={vRef as unknown as Element}
          open={visible}
          onClose={handleClose}
          anchorReference="anchorPosition"
          disableEnforceFocus={true}
          BackdropProps={{ onContextMenu: handleClose }}
          anchorPosition={
            location.y !== null && location.x !== null
              ? { top: location.y, left: location.x }
              : undefined
          }
        >
          {innerBody(contextClick, handleClose, card, vprops, vlongprops, dark)}
        </StyledMenu>
      ) : (
        <Dialog
          fullScreen={true}
          open={visible}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {card.name} {card.id}
          </DialogTitle>

          {innerBody(contextClick, handleClose, card, vprops, vlongprops, dark)}

          <DialogActions>
            <Button
              onClick={
                handleClose as unknown as React.MouseEventHandler<HTMLButtonElement>
              }
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>

    // </div>
  );
};
function innerBody(
  contextClick: React.MouseEventHandler<HTMLDivElement>,
  handleClose: React.MouseEventHandler<HTMLDivElement>,
  card: DigimonData,
  vprops: { key: string; value: any }[],
  vlongprops: { key: any; value: any }[],
  dark: string
) {
  return (
    <div
      id="contextBackground"
      className={cssStyle.contextMenu + " contextMenu " + dark}
      onContextMenu={contextClick}
    >
      <div className={cssStyle.close} onMouseDown={handleClose}>
        <CancelIcon style={{ color: "#555" }} />
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1" }}>
          <CardImage noZoom={true} data={card} size={2} />
        </div>
        <div className={cssStyle.subCol}>
          <div className={cssStyle.name}>{card.name}</div>
          {vprops.map((item, index) => (
            <div key={item.key} className={cssStyle.property}>
              <div className={cssStyle.label}>{item.key}</div>
              <div className={cssStyle.value}>{item.value}</div>
            </div>
          ))}
          <div
            className={cssStyle.tooltip}
            title="Prices updated periodically from TCGPlayer.com (MarketPrice)."
          >
            <div className={cssStyle.property}>
              <div className={cssStyle.label}>Price</div>
              <div className={cssStyle.value}>${card.price}</div>
            </div>
            {card.shop && card.shop.length > 0 && (
              <div className={cssStyle.property}>
                <div className={cssStyle.label}>
                  <img
                    style={{ verticalAlign: "middle" }}
                    src="https://assets.cardlist.dev/other/tcglogo.png"
                    alt="TCGPLAYER"
                  />
                </div>
                <div className={cssStyle.value}>
                  <b>
                    <a
                      target="_blank"
                      rel="noopener"
                      style={{ textDecoration: "none" }}
                      href={
                        "https://store.tcgplayer.com/digimon-card-game" +
                        card.shop +
                        "?utm_campaign=affiliate&utm_medium=DigimonCardDev&utm_source=DigimonCardDev"
                      }
                    >
                      Buy this card
                    </a>
                  </b>
                </div>
              </div>
            )}
          </div>
          <div className={cssStyle.property}>
            <div className={cssStyle.label}>Card Number</div>
            <div className={cssStyle.value}>{card.id}</div>
          </div>
        </div>
      </div>
      <div className={cssStyle.subCol}>
        {vlongprops.map((item, index) => {
          if (item.value.length > 0) {
            return (
              <div key={index} className={cssStyle.property}>
                <div className={cssStyle.label}>{item.key}</div>
                <div className={cssStyle.valueLong}>
                  <Desc text={item.value} />
                </div>
              </div>
            );
          }
          return <></>;
        })}
        {((card.notes ?? "").length > 0 || (card.pid ?? "").length > 0) && (
          <div className={cssStyle.property}>
            <div className={cssStyle.label}>Notes</div>
            <div className={cssStyle.valueLong}>
              <Desc text={(card.notes ?? "") + " " + (card.pid ?? "")} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
