import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import todosReducer from "../features/todos/todosSlice";
import authReducer from "../features/authStore/authSlice";
import deckReducer, { DeckState } from "../features/deckStore/deckSlice";
import coreReducer from "../features/coreuiStore/coreSlice";
import contextReducer from "../features/contextMenu/contextSlice";
import deckBuilderSlice, {
  DeckCard,
} from "src/features/deckBuilder/deckBuilderSlice";

import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform,
  persistReducer,
} from "redux-persist";
//import storage from "redux-persist/lib/storage";
import localforage from "localforage";
import helperSlice from "src/features/helperStore/helperSlice";

const compress = (workingDeck: DeckCard[] | undefined) => {
  if (workingDeck === undefined) {
    return "[]";
  }
  var compressed = [];
  for (var i = 0; i < workingDeck.length; i++) {
    compressed.push({
      q: workingDeck[i].qty,
      g: workingDeck[i].gid,
      i: workingDeck[i].baseId,
    });
  }
  return JSON.stringify(compressed);
};

export const decompress = (data: string) => {
  const json = JSON.parse(data);
  const workingDeck: DeckCard[] = [];
  for (var i = 0; i < json.length; i++) {
    var card = json[i];
    workingDeck.push({ qty: card.q, gid: card.g, baseId: card.i });
  }
  return workingDeck;
};

const DeckTransform = createTransform<
  DeckState,
  DeckState,
  DeckState,
  DeckState
>(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // convert mySet to an Array.
    var decks = inboundState.decks.map((dcd) => {
      return { d: compress(dcd.wd), n: dcd.n, s: dcd.s };
    });
    return { ...inboundState, decks };
  },
  // transform state being rehydrated
  (outboundState, key) => {
    // convert mySet back to a Set.
    var decks = outboundState.decks.map((dcd) => {
      return { n: dcd.n, s: dcd.s, wd: decompress(dcd.d), d: dcd.d };
    });
    return { ...outboundState, decks };
  },
  // define which reducers this transform gets called for.
  { whitelist: ["decks"] }
);

const persistConfig = {
  key: "root",
  version: 1,
  storage: localforage,
  //transforms: [DeckTransform],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    decks: deckReducer,
  })
);

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    todos: todosReducer,
    auth: authReducer,
    //decks: deckReducer,
    saved: persistedReducer,
    core: coreReducer,
    context: contextReducer,
    deckBuilder: deckBuilderSlice,
    helper: helperSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
