// firebase.js
// contains the Firebase context and provider

import { createContext } from "react";
import firebaseConfig from "./firebaseConfig";

import { initializeApp, getApps, FirebaseApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  //signInWithRedirect, //use for mobile
  signOut,
  UserCredential,
} from "firebase/auth";

import {
  getFirestore,
  FirebaseFirestore,
  collection,
  getDocs,
} from "firebase/firestore";
import { useDispatch } from "react-redux";

interface FbApi {
  getTodos: any;
  getTodos2: any;
  //goSignIn: any;
  //goSignOut: any;
}

interface DevUser {
  name: string;
  authUser: UserCredential["user"];
}

interface FirebaseValue {
  app: FirebaseApp;
  database: FirebaseFirestore;
  api: FbApi;
  user: DevUser;
}

// we create a React Context, for this to be accessible
// from a component later
const FirebaseContext = createContext(null as unknown as FirebaseValue);
export { FirebaseContext };

var firebase: FirebaseValue = {
  app: null as unknown as FirebaseApp,
  database: null as unknown as FirebaseFirestore,
  api: null as unknown as FbApi,
  user: null as unknown as DevUser,
};

export const FirebaseProvider = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();

  // check if firebase app has been initialized previously
  // if not, initialize with the config we saved earlier

  if (getApps().length === 0) {
    firebase = {
      app: initializeApp(firebaseConfig),
      database: getFirestore(),

      api: {
        getTodos,
        getTodos2,
        //goSignIn,
        //goSignOut,
      },
      user: null as unknown as DevUser,
    };
  }

  function getTodos() {
    // const q = query(collection(firebase.database, "todos"));
    // const unsubscribe = onSnapshot(q, (snapshot) => {
    //   var vals = snapshot.docChanges();
    //   var _records = [];
    //   for (var key in vals) {
    //     _records.push({
    //       ...vals[key].doc.data(),
    //       id: key,
    //     });
    //   }
    //   dispatch(setTodos(_records));
    // });
  }

  async function getTodos2() {
    console.info("todos2");
    try {
      const querySnapshot = await getDocs(
        collection(firebase.database, "todos")
      );
      querySnapshot.forEach((doc) => {
        console.info(`${doc.id} => ${doc.data()}`);
      });
    } catch (e) {
      console.error(e);
    }
  }

  // function goSignIn() {
  //   const auth = getAuth();
  //   const provider = new GoogleAuthProvider();
  //   signInWithPopup(auth, provider)
  //     .then((result) => {
  //       // This gives you a Google Access Token. You can use it to access the Google API.
  //       // const credential = GoogleAuthProvider.credentialFromResult(result);
  //       //const token = credential?.accessToken;
  //       // The signed-in user info.
  //       const user = result.user;
  //       firebase.user = { name: "", authUser: user };
  //       console.info(result);
  //       // ...
  //     })
  //     .catch((error) => {
  //       // // Handle Errors here.
  //       // const errorCode = error.code;
  //       // const errorMessage = error.message;
  //       // // The email of the user's account used.
  //       // const email = error.email;
  //       // // The AuthCredential type that was used.
  //       // const credential = GoogleAuthProvider.credentialFromError(error);
  //       // // ...
  //       console.info(error);
  //     });
  // }

  // function goSignOut() {
  //   const auth = getAuth();
  //   signOut(auth)
  //     .then(() => {
  //       // Sign-out successful.
  //       console.info("Signed out.");
  //       firebase.user = null as unknown as DevUser;

  //     })
  //     .catch((error) => {
  //       // An error happened.
  //       console.error(error);
  //     });
  // }

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
