import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CInputGroup,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CFormInput,
  CDropdownDivider,
  CFormCheck,
  CButton,
  CListGroup,
  CListGroupItem,
  CAlert,
  CCol,
} from "@coreui/react";
import React, { useEffect, useState, useContext, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { selectDeckBuilder } from "src/features/deckBuilder/deckBuilderSlice";
import {
  Button,
  Tooltip,
  Paper,
  Dialog,
  useMediaQuery,
  useTheme,
  DialogTitle,
  DialogContent,
  Slide,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import {
  writeLocal,
  writeOnline,
  getDecksOnline,
  selectDecks,
  addOnline,
  addLocal,
} from "src/features/deckStore/deckSlice";
import { v4 as uuidv4 } from "uuid";

import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from "@mui/icons-material/Refresh";
import { selectAuth, signInAuth } from "src/features/authStore/authSlice";
import { SearchContext } from "src/provider/searchProvider";
import AguPixel from "src/assets/icons/agupixel.png";
import { Stats } from "./Stats";
import { DecklistProfile, EmptyDecklistProfile } from "./DecklistProfile";
import styles from "./SaveButton.module.css";
import { selectCore } from "src/features/coreuiStore/coreSlice";

const Loading = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const _SaveButton: React.FC<{
  stats: {
    col: { [key: string]: number };
    lv: { [key: string]: number };
    ddto: { [key: string]: number };
    count: number;
  };
}> = ({ stats }) => {
  const [visible, setVisible] = useState(false);
  const [cloud, setCloud] = useState(false);
  const [name, setName] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [select, setSelect] = useState<undefined | number>(undefined);

  const showSave = () => {
    const maxColor = [...Object.keys(stats.col), ""].sort((a, b) => {
      return stats.col[b] - stats.col[a];
    })[0];

    const rookieRush =
      stats.lv["3"] >= stats.ddto["Digimon"] / 2 ? "Rookie Rush " : " ";
    if (workingDeck.length > 0) {
      setName(maxColor + " " + rookieRush + uuidv4().slice(0, 4));
    }
    setVisible(true);
  };

  const { workingDeck } = useAppSelector(selectDeckBuilder);

  const { decks, onlineDecks, status } = useAppSelector(selectDecks);
  //const { baseCards } = useContext(SearchContext);
  const dispatch = useAppDispatch();

  const handleSaveDeck = (index?: number) => {
    return () => {
      var compressed = [];

      for (var i = 0; i < workingDeck.length; i++) {
        let deckCard = workingDeck[i];
        compressed.push({
          q: deckCard.qty,
          g: deckCard.gid,
          i: deckCard.baseId,
        });
      }
      //console.info(stats);

      var saveName =
        name.length == 0 && workingDeck.length > 0
          ? uuidv4().slice(0, 8)
          : name;

      if (index === undefined) {
        if (cloud) {
          dispatch(
            addOnline({
              index: 0,
              deck: {
                n: saveName,
                d: JSON.stringify(compressed) || "",
                c: stats,
              },
            })
          );
        } else {
          dispatch(
            addLocal({
              index: 0,
              deck: {
                n: saveName,
                d: JSON.stringify(compressed) || "",
                c: stats,
              },
            })
          );
          setVisible(false);
        }
      } else {
        if (cloud) {
          dispatch(
            writeOnline({
              index: index,
              deck: {
                n: saveName,
                d: JSON.stringify(compressed) || "",
                c: stats,
              },
            })
          );
        } else {
          dispatch(
            writeLocal({
              index: index,
              deck: {
                n: saveName,
                d: JSON.stringify(compressed) || "",
                c: stats,
              },
            })
          );
          setVisible(false);
        }
      }
    };
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    // @ts-ignore
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setName(event.target.value);
  };
  const { auth, status: authStatus } = useAppSelector(selectAuth);
  const handleCloudRefresh = () => {
    if (authStatus == "signedIn") {
      dispatch(getDecksOnline(auth.uid));
    }

    setCloud(true);
  };

  const toggleCloud: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    (event.target as HTMLElement).blur();
    setCloud(!cloud);
  };

  const disabled = workingDeck.length == 0;
  const offline = authStatus != "signedIn";
  useEffect(() => {
    if (offline == false && cloud == false) {
      setCloud(true);
    }
  }, [offline]);
  const selectedDeck = cloud ? (offline ? [] : onlineDecks) : decks;
  const theme = useTheme();

  const handleConfirmClose = () => {
    setConfirm(false);
    setSelect(undefined);
  };

  const handleConfirm = (index: number) => {
    return () => {
      setSelect(index);
      setConfirm(true);
    };
  };

  const ConfirmModal: React.FC<{
    open: boolean;
    onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
    dark: string;
  }> = ({ open, onClose, dark }) => {
    const agree = () => {
      setConfirm(false);
      handleSaveDeck(select)();
      setSelect(undefined);
    };

    return (
      <Dialog
        className={dark}
        open={open}
        // @ts-ignore
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        fullScreen={useMediaQuery(theme.breakpoints.down("md"))}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{"Replace an older deck?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Newer deck</DialogContentText>
          <Paper elevation={2} className={"m-2 p-2"} style={{ width: "100%" }}>
            <Stats stats={stats} />
          </Paper>

          {select != undefined && (
            <>
              <DialogContentText>
                Older deck: {selectedDeck[select as number].n}
              </DialogContentText>
              <Paper
                elevation={2}
                className={"m-2 p-2"}
                style={{ width: "100%" }}
              >
                <Stats stats={selectedDeck[select as number].c!} />
              </Paper>
            </>
          )}
          <DialogContentText>This is a permanent change.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={
              onClose as unknown as React.MouseEventHandler<HTMLButtonElement>
            }
          >
            Cancel
          </Button>
          <Button onClick={agree}>Save</Button>
        </DialogActions>
      </Dialog>
    );
  };

  console.log(cloud, offline);

  const { theme: themeColor } = useAppSelector(selectCore);
  const dark = themeColor == "dark" ? "dark" : "";
  return (
    <>
      <Button onClick={showSave}>
        <SaveIcon />
        Save
      </Button>

      <Dialog
        fullScreen={useMediaQuery(theme.breakpoints.down("md"))}
        open={visible}
        fullWidth
        maxWidth="md"
        className={dark}
      >
        <ConfirmModal open={confirm} onClose={handleConfirmClose} dark={dark} />
        <CModalHeader onDismiss={() => setVisible(false)}>
          <CModalTitle>Save Deck</CModalTitle>
        </CModalHeader>

        <CModalHeader className="dcback" style={{ flexDirection: "column" }}>
          <CInputGroup>
            <CButton
              type="button"
              color="secondary"
              onClick={() => {
                setCloud(!cloud);
              }}
            >
              {cloud ? "Cloud Save" : "Local Save"}
            </CButton>
            <CFormInput
              value={name}
              onChange={handleNameChange}
              aria-label="Text input with dropdown button"
              placeholder="Deck Name"
            />
          </CInputGroup>
          <Paper elevation={2} className={"m-2 p-2"} style={{ width: "100%" }}>
            <Stats stats={stats} />
          </Paper>
          <div className="d-flex" style={{ width: "100%" }}>
            <CloudToggle
              {...{ cloud, toggleCloud, handleCloudRefresh, offline }}
            />
            <div className={styles.slots}>
              {(cloud ? 20 : 50) - selectedDeck.length} free save slots.
            </div>
          </div>
        </CModalHeader>
        <DialogContent dividers={true} sx={{ p: 0 }}>
          <CModalBody className="dcback">
            {disabled ? (
              <CAlert color="warning">You can't save an empty deck.</CAlert>
            ) : offline && cloud ? (
              <CAlert color="warning">
                <CButton onClick={() => dispatch(signInAuth())}>
                  Sign In
                </CButton>{" "}
                to save on the cloud.
              </CAlert>
            ) : (
              <>
                <EmptyDecklistProfile
                  name={name}
                  onClick={handleSaveDeck(selectedDeck.length + 1)}
                />
                {status == "idle" ? (
                  <CListGroup>
                    {selectedDeck.map((val, i, arr) => {
                      const index = arr.length - 1 - i;
                      const reversed = arr[arr.length - 1 - i];
                      return (
                        <DecklistProfile
                          index={index + 1}
                          deck={reversed}
                          key={reversed.n}
                          onClick={handleConfirm(index)}
                        />
                      );
                    })}
                  </CListGroup>
                ) : (
                  <Loading />
                )}
              </>
            )}
          </CModalBody>
        </DialogContent>
        <CModalFooter>
          Select a deck slot above.
          <Button color="secondary" onClick={() => setVisible(false)}>
            Close
          </Button>
        </CModalFooter>
      </Dialog>
      {/* 
      <CModal scrollable fullscreen="md" visible={visible} size="lg">
        <CModalHeader onDismiss={() => setVisible(false)}>
          <CModalTitle>Save Deck</CModalTitle>
        </CModalHeader>
        <CModalHeader className="dcback" style={{ flexDirection: "column" }}>
          <CInputGroup>
            <CButton
              type="button"
              color="secondary"
              onClick={() => {
                setCloud(!cloud);
              }}
            >
              {cloud ? "Cloud Save" : "Local Save"}
            </CButton>
            <CFormInput
              value={name}
              onChange={handleNameChange}
              aria-label="Text input with dropdown button"
              placeholder="Deck Name"
            />
          </CInputGroup>
          <Paper elevation={2} className={"m-2 p-2"} style={{ width: "100%" }}>
            <Stats stats={wdStats} />
          </Paper>
          <div className="d-flex" style={{ width: "100%" }}>
            <CInputGroup>
              <CButton
                color="success"
                variant={cloud ? "outline" : undefined}
                className={!cloud ? styles.toggleOn : ""}
                // @ts-ignore
                checked={cloud}
                onClick={toggleCloud}
              >
                Local
              </CButton>
              <CButton
                color="success"
                variant={!cloud ? "outline" : undefined}
                className={cloud ? styles.toggleOn : ""}
                // @ts-ignore
                checked={cloud}
                onClick={toggleCloud}
              >
                Cloud
              </CButton>
              <Tooltip title="Refresh cloud saves">
                <span>
                  <CButton
                    onClick={handleCloudRefresh}
                    color="secondary"
                    disabled={offline}
                  >
                    <RefreshIcon />
                  </CButton>
                </span>
              </Tooltip>
            </CInputGroup>
          </div>
        </CModalHeader>
        <CModalBody className="dcback">
          {disabled ? (
            <CAlert color="warning">You can't save an empty deck.</CAlert>
          ) : offline && cloud ? (
            <CAlert color="warning">
              <CButton onClick={() => dispatch(signInAuth())}>Sign In</CButton>{" "}
              to save on the cloud.
            </CAlert>
          ) : (
            <>
              <EmptyDecklistProfile name={name} />
              {status == "idle" ? (
                <CListGroup>
                  {selectedDeck.map((val, i) => {
                    return (
                      <DecklistProfile
                        deck={val}
                        key={val.n}
                        onClick={handleSaveDeck(i)}
                      />
                    );
                  })}
                </CListGroup>
              ) : (
                <Loading />
              )}
            </>
          )}
        </CModalBody>
        <CModalFooter>
          Select a deck slot above.
          <Button color="secondary" onClick={() => setVisible(false)}>
            Close
          </Button>
        </CModalFooter>
      </CModal>
    */}
    </>
  );
};

export const SaveButton = React.memo(_SaveButton);
export const CloudToggle: React.FC<{
  cloud: boolean;
  toggleCloud: React.MouseEventHandler<HTMLButtonElement>;
  handleCloudRefresh: () => void;
  offline: boolean;
}> = ({ cloud, toggleCloud, handleCloudRefresh, offline }) => {
  return (
    <CInputGroup>
      <CButton
        color="success"
        variant={cloud ? "outline" : undefined}
        className={!cloud ? styles.toggleOn : ""}
        // @ts-ignore
        checked={cloud}
        onClick={toggleCloud}
      >
        Local
      </CButton>
      <CButton
        color="success"
        variant={!cloud ? "outline" : undefined}
        className={cloud ? styles.toggleOn : ""}
        // @ts-ignore
        checked={cloud}
        onClick={toggleCloud}
      >
        Cloud
      </CButton>
      <Tooltip title="Refresh cloud saves">
        <span>
          <CButton
            onClick={handleCloudRefresh}
            color="secondary"
            disabled={offline}
          >
            <RefreshIcon />
          </CButton>
        </span>
      </Tooltip>
    </CInputGroup>
  );
};
