import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { type } from "os";
import { RootState, AppThunk } from "../../app/store";

export interface CoreState {
  [k: string]: any;
  sidebarShow: boolean;
  status: "idle" | "loading" | "failed";
  theme: "dark" | "light" | "preferred";
}

const initialState: CoreState = {
  sidebarShow: false,
  status: "idle",
  theme: "dark",
};

export const coreSlice = createSlice({
  name: "core",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCore: (state, action: PayloadAction<{ [k: string]: any }>) => {
      return (state = { ...state, ...action.payload });
    },
    toggleDark: (state) => {
      state.theme = state.theme === "dark" ? "light" : "dark";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(incrementAsync.pending, (state) => {
  //         state.status = "loading";
  //       })
  //       .addCase(incrementAsync.fulfilled, (state, action) => {
  //         state.status = "idle";
  //         state.value += action.payload;
  //       });
  //   },
});

export const { setCore, toggleDark } = coreSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectCount = (state: RootState) => state.counter.value;
export const selectCore = (state: RootState) => state.core;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default coreSlice.reducer;
