const firebaseConfig = {
  apiKey: "AIzaSyDEaUwBgBspo4p20d6Tu6vL4ipNtNwNHA4",
  authDomain: "digimoncarddev.firebaseapp.com",
  databaseURL: "https://digimoncarddev.firebaseio.com",
  projectId: "digimoncarddev",
  storageBucket: "digimoncarddev.appspot.com",
  messagingSenderId: "421941714852",
  appId: "1:421941714852:web:5de7116f514577cdbd2b31",
  measurementId: "G-D7X7WZVLXR",
};

export default firebaseConfig;
