import React from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { useAppDispatch } from "src/app/hooks";
import {
  selectDeckBuilder,
  setDeckCardSize,
  setSearchCardSize,
} from "src/features/deckBuilder/deckBuilderSlice";
import { Button } from "@mui/material";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

export const ZoomOptions: React.FC<{
  mode: "builder" | "search";
  step?: number;
}> = ({ mode, step }) => {
  const dispatch = useAppDispatch();

  var setter: ActionCreatorWithPayload<number, string>;
  switch (mode) {
    case "builder":
      setter = setDeckCardSize;
      break;
    case "search":
      setter = setSearchCardSize;
      break;
  }
  return (
    <>
      <Button
        onClick={() => {
          dispatch(setter(step ?? 0.25));
        }}
      >
        <ZoomInIcon />
      </Button>
      <Button
        onClick={() => {
          dispatch(setter(-(step ?? 0.25)));
        }}
      >
        <ZoomOutIcon />
      </Button>
    </>
  );
};
