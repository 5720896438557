import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";

export interface ContextState {
  baseId: number;
  visible: boolean;
  location: { x: number; y: number };
}

const initialState: ContextState = {
  baseId: 287,
  visible: false,
  location: { x: 100, y: 100 },
};

export const contextSlice = createSlice({
  name: "context",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setContext: (state, action: PayloadAction<ContextState>) => {
      state.visible = action.payload.visible;
      state.baseId = action.payload.baseId;
      state.location = action.payload.location;
    },
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(incrementAsync.pending, (state) => {
  //         state.status = "loading";
  //       })
  //       .addCase(incrementAsync.fulfilled, (state, action) => {
  //         state.status = "idle";
  //         state.value += action.payload;
  //       });
  //   },
});

export const { setContext, setVisible } = contextSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectCount = (state: RootState) => state.counter.value;
export const selectContext = (state: RootState) => state.context;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default contextSlice.reducer;
