import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DigimonData } from "src/data/cardInfo";
import { RootState, AppThunk } from "../../app/store";

export interface DeckCard {
  baseId: number;
  qty: number;
  gid: number;
}

export interface DeckBuilderState {
  location: "end" | "bottom";
  visible: boolean;
  size: number;
  workingDeck: DeckCard[];
  eggCount: number;
  mainCount: number;
  deckCardSize: number;
  searchCardSize: number;
  builderVisible: boolean;
}

const initialState: DeckBuilderState = {
  location: "end",
  visible: true,
  size: 30,
  workingDeck: [],
  eggCount: 0,
  mainCount: 0,
  deckCardSize: 0.5,
  searchCardSize: 1,
  builderVisible: false,
};

export const decompress = (data: string) => {
  const json = JSON.parse(data);
  const workingDeck: DeckCard[] = [];
  for (var i = 0; i < json.length; i++) {
    var card = json[i];
    workingDeck.push({ qty: card.q, gid: card.g, baseId: card.i });
  }
  return workingDeck;
};

export const deckBuilderSlice = createSlice({
  name: "deckBuilder",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearAll: (state) => {
      state.workingDeck = [];
      state.eggCount = 0;
      state.mainCount = 0;
    },
    togglePosition: (state) => {
      state.location = state.location === "bottom" ? "end" : "bottom";
    },
    setPosition: (state, action: PayloadAction<"end" | "bottom">) => {
      state.location = action.payload;
    },
    toggleSize: (state) => {
      switch (state.size) {
        case 30:
          state.size = 50;
          return;
        case 50:
          state.size = 80;
          return;
        default:
          state.size = 30;
      }
    },
    toggleBuilderVisible: (state) => {
      state.builderVisible = !state.builderVisible;
    },
    setVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    removeCard: (
      state,
      action: PayloadAction<{ baseId: number; baseCards: DigimonData[] }>
    ) => {
      // logic to check cards
      for (var i = 0; i < state.workingDeck.length; i++) {
        var deckCard = state.workingDeck[i];
        if (deckCard.baseId === action.payload.baseId) {
          //assume that card qty is always valid
          if (action.payload.baseCards[deckCard.baseId].ctype !== "Digi-Egg") {
            state.mainCount--;
          } else {
            state.eggCount--;
          }
          deckCard.qty--;
          if (deckCard)
            if (deckCard.qty === 0) {
              state.workingDeck.splice(i, 1);
            }
          return;
        }
      }
    },
    setDeckCardSize: (state, action: PayloadAction<number>) => {
      if (action.payload == 0) {
        state.deckCardSize = initialState.deckCardSize;
        return;
      }
      state.deckCardSize = Math.max(state.deckCardSize + action.payload, 0.25);
    },
    setSearchCardSize: (state, action: PayloadAction<number>) => {
      if (action.payload === 0) {
        state.searchCardSize = initialState.searchCardSize;
        return;
      }
      state.searchCardSize = Math.max(
        state.searchCardSize + action.payload,
        0.25
      );
    },
    setWorkingDeck: (
      state,
      action: PayloadAction<{
        workingDeck: DeckCard[];
        baseCards: DigimonData[];
      }>
    ) => {
      state.workingDeck = action.payload.workingDeck;
      let egg = 0;
      let main = 0;
      for (var i = 0; i < state.workingDeck.length; i++) {
        if (
          action.payload.baseCards[state.workingDeck[i].baseId].ctype !==
          "Digi-Egg"
        ) {
          main = main + state.workingDeck[i].qty;
        } else {
          egg = egg + state.workingDeck[i].qty;
        }
      }
      state.mainCount = main;
      state.eggCount = egg;
    },
    setWorkingDeckData: (
      state,
      action: PayloadAction<{
        workingDeckData: string;
        baseCards: DigimonData[];
      }>
    ) => {
      state.workingDeck = decompress(action.payload.workingDeckData);
      let egg = 0;
      let main = 0;
      for (var i = 0; i < state.workingDeck.length; i++) {
        if (
          action.payload.baseCards[state.workingDeck[i].baseId].ctype !==
          "Digi-Egg"
        ) {
          main = main + state.workingDeck[i].qty;
        } else {
          egg = egg + state.workingDeck[i].qty;
        }
      }
      state.mainCount = main;
      state.eggCount = egg;
    },
    addCard: (
      state,
      action: PayloadAction<{ baseId: number; baseCards: DigimonData[] }>
    ) => {
      // logic to check cards
      for (var i = 0; i < state.workingDeck.length; i++) {
        var deckCard = state.workingDeck[i];
        if (deckCard.baseId === action.payload.baseId) {
          if (
            (action.payload.baseCards[deckCard.baseId].id === "BT6-085" &&
              deckCard.qty < 50) ||
            deckCard.qty < 4
          ) {
            deckCard.qty++;
            if (
              action.payload.baseCards[deckCard.baseId].ctype !== "Digi-Egg"
            ) {
              state.mainCount++;
            } else {
              state.eggCount++;
            }
          }
          return;
        }
      }
      //not yet in deck
      state.workingDeck.push({
        qty: 1,
        baseId: action.payload.baseId,
        gid: action.payload.baseCards[action.payload.baseId].gid || -1,
      });
      if (
        action.payload.baseCards[action.payload.baseId].ctype !== "Digi-Egg"
      ) {
        state.mainCount++;
      } else {
        state.eggCount++;
      }
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(incrementAsync.pending, (state) => {
  //         state.status = "loading";
  //       })
  //       .addCase(incrementAsync.fulfilled, (state, action) => {
  //         state.status = "idle";
  //         state.value += action.payload;
  //       });
  //   },
});

export const {
  setPosition,
  togglePosition,
  setVisible,
  addCard,
  removeCard,
  toggleSize,
  toggleBuilderVisible,
  setDeckCardSize,
  setSearchCardSize,
  clearAll,
  setWorkingDeck,
  setWorkingDeckData,
} = deckBuilderSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectCount = (state: RootState) => state.counter.value;
export const selectDeckBuilder = (state: RootState) => state.deckBuilder;
export const seelctSearchCardSizeAndBuilderVisible = (state: RootState) => {
  return {
    searchCardSize: state.deckBuilder.searchCardSize,
    builderVisible: state.deckBuilder.builderVisible,
  };
};

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default deckBuilderSlice.reducer;
