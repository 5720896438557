import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  UserCredential,
} from "firebase/auth";
import { RootState, AppThunk } from "../../app/store";
import { FirebaseValue, FbApi, DevUser } from "../../firebaseInterfaces";

export interface AuthState {
  auth: DevUser;
  status: "idle" | "loading" | "failed" | "signedIn";
}

const initialState: AuthState = {
  auth: null as unknown as DevUser,
  status: "idle",
};

export const signInAuth = createAsyncThunk("auth/signInPopup", async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  var response = null as unknown as UserCredential;
  try {
    response = await signInWithPopup(auth, provider);
  } catch (error) {
    console.error(error);
  }

  // The value we return becomes the `fulfilled` action payload
  const user: DevUser = {
    name: response.user.displayName || "",
    uid: response.user.uid,
    email: response.user.email || "",
  };
  return user;
});

export const signOutAuth = createAsyncThunk(
  "auth/signOut",
  async (_, { rejectWithValue }) => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    var response = null;
    try {
      response = await signOut(auth);
      console.info("Signed out.");
    } catch (error) {
      rejectWithValue(error);
    }

    // The value we return becomes the `fulfilled` action payload
    return;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuth: (
      state,
      action: PayloadAction<{
        auth: DevUser;
        status: "idle" | "loading" | "failed" | "signedIn";
      }>
    ) => {
      state.auth = action.payload.auth;
      state.status = action.payload.status;
    },
  },
  //   The `extraReducers` field lets the slice handle actions defined elsewhere,
  //   including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(signInAuth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signInAuth.fulfilled, (state, action) => {
        state.status = "signedIn";
        state.auth = action.payload;
      })
      .addCase(signInAuth.rejected, (state, action) => {
        state.status = "failed";
        state.auth = null as unknown as DevUser;
      })
      .addCase(signOutAuth.fulfilled, (state, action) => {
        state.status = "idle";
        state.auth = null as unknown as DevUser;
      })
      .addCase(signOutAuth.rejected, (state, action) => {
        state.status = "failed";
        state.auth = null as unknown as DevUser;
      });
  },
});

export const { setAuth } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectCount = (state: RootState) => state.counter.value;
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
