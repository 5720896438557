import React, { useState } from "react";

//import html2canvas from "html2canvas";
import { DeckCard } from "src/features/deckBuilder/deckBuilderSlice";
import { DigimonData } from "src/data/cardInfo";
import styles from "./CustomDeck.module.css";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import domtoimage from "dom-to-image";

const cardTypeOrder: { [key: string]: number } = {
  "Digi-Egg": 0,
  Digimon: 1,
  Tamer: 2,
  Option: 3,
};

export const CustomDeck: React.FC<{
  deck: DeckCard[];
  baseCards: DigimonData[];
}> = ({ deck, baseCards }) => {
  const [loading, setLoading] = useState(false);
  const [quality, setQuality] = React.useState("1");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuality((event.target as HTMLInputElement).value);
  };

  const sortedDeck = [...deck].sort((a, b) => {
    //sort by type first
    var aCard = baseCards[a.baseId];
    var bCard = baseCards[b.baseId];
    if (cardTypeOrder[aCard.ctype] - cardTypeOrder[bCard.ctype] == 0) {
      if (aCard.ctype == "Digimon") {
        if ((aCard.lv ?? 100) - (bCard.lv ?? 100) == 0) {
          return aCard.id.localeCompare(bCard.id);
        } else {
          return (aCard.lv ?? 100) - (bCard.lv ?? 100);
        }
      }
      return aCard.id.localeCompare(bCard.id);
    } else {
      return cardTypeOrder[aCard.ctype] - cardTypeOrder[bCard.ctype];
    }
  });

  var arr = [];
  var cols = [];
  for (var i = 0; i < sortedDeck.length; i++) {
    for (var j = 0; j < sortedDeck[i].qty; j++) {
      if (arr.length >= 10) {
        cols.push(arr);
        arr = [];
      }
      arr.push(
        <img
          key={arr.length}
          src={baseCards[sortedDeck[i].baseId].imageUrl}
          draggable={false}
        />
      );
    }
  }
  if (arr.length != 0) {
    cols.push(arr);
  }
  var scale = Math.min(0.08, (window.innerWidth * 0.9) / (10 * 744));
  var height = Math.max(2, cols.length) * 1040;
  var width = 10 * 744;

  const handleDownload = () => {
    setLoading(true);
    domtoimage
      .toJpeg(document.querySelector("#ttsImage") as unknown as HTMLElement, {
        quality: quality == "1" ? 0.8 : 0.6,
      })
      .then((dataUrl) => {
        // var dataUrl = canvas.toDataURL();
        var link = document.createElement("a");
        link.download = "customDeck.jpg";
        link.href = dataUrl;
        setLoading(false);
        link.click();
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              variant="contained"
              onClick={handleDownload}
              disabled={loading}
            >
              Click to Download
            </Button>
            {loading && (
              <CircularProgress
                thickness={10}
                size={24}
                sx={{
                  color: "blue",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Box>

        <RadioGroup
          row
          aria-label="quality"
          name="controlled-radio-buttons-group"
          value={quality}
          onChange={handleChange}
        >
          <FormControlLabel
            value="1"
            control={<Radio />}
            label="High Quality"
          />
          <FormControlLabel value="2" control={<Radio />} label="Low Quality" />
        </RadioGroup>
      </Box>
      <div style={{ width: width * scale, height: height * scale }}>
        <div className={styles.custom} style={{ transform: `scale(${scale})` }}>
          <div
            className={styles.wrapper}
            style={{ width, height }}
            id="ttsImage"
          >
            {cols.map((val, i) => (
              <div key={i}>{val}</div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomDeck;
