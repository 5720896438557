import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { RootState } from "../../app/store";
import { DeckCard } from "../deckBuilder/deckBuilderSlice";

export interface Counts {
  col: { [key: string]: number };
  lv: { [key: string]: number };
  ddto: { [key: string]: number };
  count: number;
}

export interface DCDeck {
  d: string; // data encoded
  n: string; // name
  c?: Counts; // counts
  s?: number | Timestamp; // save date
  o?: string; // cover card id
  wd?: DeckCard[]; // working deck
}

export interface DeckState {
  decks: DCDeck[];
  onlineDecks: DCDeck[];
  status: "idle" | "loading" | "failed" | "success";
}

const initialState: DeckState = {
  decks: [],
  onlineDecks: [],
  status: "idle",
};

export const getDecksOnline = createAsyncThunk(
  "decks/getDecks",
  async (userId: string) => {
    console.info("Getting decks from online store");
    var response = null as unknown as DCDeck[];
    try {
      const db = getFirestore();
      const userRef = doc(db, `users/${userId}`);
      //const q = query(usersRef, where("id", "==", userId));
      const docSnap = await getDoc(userRef);
      if (!docSnap.exists()) {
        console.info("Doc doesn't exist.");
        return [];
      }
      const docData = docSnap.data() as { decks: DCDeck[] };
      response = docData.decks.map((deck) => {
        deck.s =
          deck.s != undefined
            ? (deck?.s as Timestamp)?.seconds
            : Date.now() / 1000;
        return deck;
      });
      console.info(docData);
    } catch (e) {
      console.error(e);
      throw Error("Error can't fetch");
    }

    // The value we return becomes the `fulfilled` action payload
    const decks: DCDeck[] = response;
    return decks;
  }
);

export const setDecksOnline = async (userId: string, onlineDecks: DCDeck[]) => {
  console.info("Upload decks");
  var response = null as unknown as DCDeck[];
  try {
    const db = getFirestore();
    const userRef = doc(db, `users/${userId}`);
    const convertedTimestamp = onlineDecks.map((deck) => {
      const newDeck = { ...deck, s: new Timestamp(deck.s as number, 0) };
      return newDeck;
    });
    return setDoc(userRef, { decks: convertedTimestamp }, { merge: true }).then(
      () => {
        console.info("Save sucess");
      },
      () => {
        console.error("Save failed");
      }
    );
  } catch (e) {
    console.error(e);
    throw Error("Error can't save");
  }
};

export const deckSlice = createSlice({
  name: "decks",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDecks: (
      state,
      action: PayloadAction<{
        decks: DCDeck[];
        status: "idle" | "loading" | "failed";
      }>
    ) => {
      state.decks = action.payload.decks;
      state.status = action.payload.status;
    },
    moveToOnline: (state, action: PayloadAction<{ index: number }>) => {
      var target = state.decks[action.payload.index];
      state.decks.splice(action.payload.index, 1);
      state.onlineDecks.push(target);
    },
    moveToLocal: (state, action: PayloadAction<{ index: number }>) => {
      var target = state.onlineDecks[action.payload.index];
      state.onlineDecks.splice(action.payload.index, 1);
      state.decks.push(target);
    },
    deleteLocal: (state, action: PayloadAction<{ index: number }>) => {
      state.decks.splice(action.payload.index, 1);
    },
    deleteOnline: (state, action: PayloadAction<{ index: number }>) => {
      state.onlineDecks.splice(action.payload.index, 1);
    },
    addLocal: (
      state,
      action: PayloadAction<{ index: number; deck: DCDeck }>
    ) => {
      action.payload.deck.s = Date.now() / 1000;
      state.decks.splice(action.payload.index, 0, action.payload.deck);
    },
    writeLocal: (
      state,
      action: PayloadAction<{ index: number; deck: DCDeck }>
    ) => {
      action.payload.deck.s = Date.now() / 1000;
      state.decks.splice(action.payload.index, 1, action.payload.deck);
    },
    addOnline: (
      state,
      action: PayloadAction<{ index: number; deck: DCDeck }>
    ) => {
      action.payload.deck.s = Date.now() / 1000;
      state.onlineDecks.splice(action.payload.index, 0, action.payload.deck);
    },
    writeOnline: (
      state,
      action: PayloadAction<{ index: number; deck: DCDeck }>
    ) => {
      action.payload.deck.s = Date.now() / 1000;
      state.onlineDecks.splice(action.payload.index, 1, action.payload.deck);
    },
    refreshDecks: (state, action: PayloadAction<{}>) => {},
  },
  //   The `extraReducers` field lets the slice handle actions defined elsewhere,
  //   including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getDecksOnline.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDecksOnline.fulfilled, (state, action) => {
        state.status = "idle";
        state.onlineDecks = action.payload;
      });
  },
});

export const {
  setDecks,
  moveToLocal,
  moveToOnline,
  deleteLocal,
  deleteOnline,
  addLocal,
  addOnline,
  writeLocal,
  writeOnline,
} = deckSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectCount = (state: RootState) => state.counter.value;
export const selectDecks = (state: RootState) => state.saved.decks;

export default deckSlice.reducer;
