import * as React from "react";
import Box from "@mui/material/Box";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
const minDistance = 10;

interface AirbnbThumbComponentProps extends React.HTMLAttributes<unknown> {}

function AirbnbThumbComponent(props: AirbnbThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <DoubleArrowIcon />
    </SliderThumb>
  );
}

const StyledSlider = styled(Slider)({
  color: "#1976d2",
  height: 8,
  margin: "0 1em",
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#1976d2",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export const ImportSlider: React.FC<{
  disabled?: boolean;
  onCommit?: () => void;
}> = ({ disabled, onCommit }) => {
  const [value2, setValue2] = React.useState<number[]>([0, 10]);

  const handleChange2 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setValue2([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue2([clamped - minDistance, clamped]);
      }
    } else {
      setValue2(newValue as number[]);
    }
  };
  const onChangeCommit = (
    event: React.SyntheticEvent | Event,
    value: number | Array<number>
  ) => {
    if (!Array.isArray(value)) {
      return;
    }
    if (value[0] < 89) {
      setValue2([0, 10]);
      return;
    }
    onCommit && onCommit();
  };

  function valueLabelFormat(value: number) {
    return `${value}%`;
  }

  return (
    <StyledSlider
      disabled={disabled}
      components={{ Thumb: AirbnbThumbComponent }}
      value={value2}
      onChange={handleChange2}
      onChangeCommitted={onChangeCommit}
      valueLabelFormat={valueLabelFormat}
      valueLabelDisplay="auto"
      disableSwap
    />
  );
};

export default ImportSlider;
