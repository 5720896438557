import { useEffect, useState, useMemo } from "react";
import { createContext } from "react";

// @ts-ignore
//import si from "search-index";
import { DigimonData } from "src/data/cardInfo";
import { dataMapper, dataMapperLowerCase } from "src/data/dataMapper";

// @ts-ignore
//import data from "../data";

// @ts-ignore
import FuzzySearch from "fz-search";
//import { serverTimestamp } from "firebase/firestore";
import {
  FilterJsonNode,
  Filter,
  FilterJsonRule,
  FilterOperator,
  FilterJsonRuleOp,
  Funnel,
  JsonToFunnel,
} from "funnelme";
import { fieldSorter } from "src/util/fieldSorter";

interface SearchValue {
  showResults: boolean;
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
  search: (query: string) => void;
  addFilter: (filterRule: FilterJsonRule) => void;
  results: any[];
  searcher: any;
  filter: FilterJsonNode;
  baseCards: DigimonData[];
  lcBaseCards: DigimonData[];
  deleteAtPath: (path: string) => void;
  updateAtPath: (path: string, rule: FilterJsonRule | FilterJsonNode) => void;
  clearAll: () => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  gbMap: Map<number, number>;
  sortOrderAsc: boolean[];
  setSortOrderAsc: React.Dispatch<React.SetStateAction<boolean[]>>;
  sortBy: string[];
  setSortBy: React.Dispatch<React.SetStateAction<string[]>>;
  filterResults: DigimonData[];
  setFilterResults: React.Dispatch<React.SetStateAction<DigimonData[]>>;
  sourceData: SourceStruct[];
  setSourceData: (value: SourceStruct[]) => void;
  ciBiMap?: Map<string, number[]>;
  setCiBiMap?: React.Dispatch<
    React.SetStateAction<Map<string, number[]> | undefined>
  >;
  nameData: string[];
  setNameData: React.Dispatch<React.SetStateAction<string[]>>;
}

function debounce(fn: any, time: number) {
  let timeoutId: NodeJS.Timeout | null;
  wrapper.cancel = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };
  return wrapper;
  function wrapper(...args: any[]) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
}

export interface SourceStruct {
  intl: number;
  name: string;
}
// we create a React Context, for this to be accessible
// from a component later
const SearchContext = createContext(null as unknown as SearchValue);
export { SearchContext };

var searchValue: SearchValue = {
  showResults: false,
  setShowResults: null as unknown as React.Dispatch<
    React.SetStateAction<boolean>
  >,
  search: null as unknown as (query: string) => {},
  addFilter: null as unknown as (filterRule: FilterJsonRule) => void,
  results: [],
  filter: null as unknown as FilterJsonNode,
  searcher: null as unknown as any,
  baseCards: [] as DigimonData[],
  lcBaseCards: [] as DigimonData[],
  deleteAtPath: null as unknown as (path: string) => void,
  updateAtPath: null as unknown as (
    path: string,
    rule: FilterJsonRule | FilterJsonNode
  ) => void,
  clearAll: null as unknown as () => void,
  inputValue: "",
  setInputValue: null as unknown as React.Dispatch<
    React.SetStateAction<string>
  >,
  gbMap: null as unknown as Map<number, number>,
  sortOrderAsc: [],
  setSortOrderAsc: null as unknown as React.Dispatch<
    React.SetStateAction<boolean[]>
  >,
  sortBy: [],
  setSortBy: null as unknown as React.Dispatch<React.SetStateAction<string[]>>,
  filterResults: [],
  setFilterResults: null as unknown as React.Dispatch<
    React.SetStateAction<DigimonData[]>
  >,
  sourceData: [],
  setSourceData: null as unknown as (value: SourceStruct[]) => void,
  nameData: [],
  setNameData: null as unknown as React.Dispatch<
    React.SetStateAction<string[]>
  >,
};
var initialFilter: FilterJsonNode = {
  op: FilterOperator.AND,
  nodes: [],
  type: "node",
};

export const SearchProvider = ({ children }: { children: JSX.Element }) => {
  //const dispatch = useDispatch();

  const [results, setResults] = useState<any[]>([]);
  const [baseCards, setBaseCards] = useState<DigimonData[]>([]);
  const [lcBaseCards, setLcBaseCards] = useState<DigimonData[]>([]); //lower case
  const [gbMap, setGbMap] = useState(new Map<number, number>());
  const [ciBiMap, setCiBiMap] = useState<Map<string, number[]> | undefined>(
    undefined
  );
  const [filter, setFilter] = useState<FilterJsonNode>({
    op: FilterOperator.AND,
    nodes: [],
    type: "node",
  });
  const [inputValue, setInputValue] = useState("");
  const [sortOrderAsc, setSortOrderAsc] = useState<boolean[]>([true]);
  const [sortBy, setSortBy] = useState<string[]>(["id"]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [filterResults, setFilterResults] = useState<DigimonData[]>([]);
  const [sourceData, setSourceData] = useState<SourceStruct[]>([]);
  const [nameData, setNameData] = useState<string[]>([]);
  var options = {};

  useEffect(() => {
    var trueFilter = Funnel(JsonToFunnel<DigimonData>(filter));
    if (filter.nodes.length > 0) {
      var res = lcBaseCards
        .filter(trueFilter)
        .sort(fieldSorter(sortBy, sortOrderAsc));
      setFilterResults(res);
      console.log("Searching...");
    } else {
      setFilterResults([]);
    }
  }, [filter, sortBy, sortOrderAsc, lcBaseCards]);

  const searchFunction = (query: string) => {
    const res = searchValue.searcher.search(query);
    res.sort(fieldSorter(sortBy, sortOrderAsc));
    setResults(res);
    //console.info(res);
  };
  searchValue.search = useMemo(() => debounce(searchFunction, 300), []);

  // @ts-ignore
  window.search = searchValue.searcher;

  const deleteAtPath = (path: string) => {
    var paths = path.split("/");
    var node: FilterJsonNode | FilterJsonRule = filter;
    var prevNode;
    for (var depth = 0; depth < paths.length - 2; depth++) {
      prevNode = node;
      node = node.nodes[parseInt(paths[depth])] as FilterJsonNode;
    }
    var index = parseInt(paths[depth]);
    var deleting = node.nodes[index] as FilterJsonNode;
    console.info("deleteing", deleting);
    node.nodes.splice(index, 1);
    if (node.nodes.length === 0) {
      index = parseInt(paths[depth - 1]);
      prevNode?.nodes.splice(index, 1);
    }
    console.info(filter);
    setFilter({ ...filter });
    if (filter.nodes.length === 0) {
      setResults([]);
    }
  };
  searchValue.deleteAtPath = deleteAtPath;

  const updateAtPath = (
    path: string,
    rule: FilterJsonRule | FilterJsonNode
  ) => {
    if (path.length === 0) {
      setFilter({ ...rule } as FilterJsonNode);
      return;
    }
    var paths = path.split("/");
    var node: FilterJsonNode | FilterJsonRule = filter;

    for (var depth = 0; depth < paths.length - 2; depth++) {
      node = node.nodes[parseInt(paths[depth])] as FilterJsonNode;
    }
    var index = parseInt(paths[depth]);
    node.nodes.splice(index, 1, rule);
    setFilter({ ...filter });
  };
  searchValue.updateAtPath = updateAtPath;

  const clearAll = () => {
    setFilter({ op: FilterOperator.AND, nodes: [], type: "node" });
    setResults([]);
  };
  searchValue.clearAll = clearAll;

  const addFilter = (filterRule: FilterJsonRule) => {
    setShowResults(true);
    //auto add to filter
    // and each field , or values
    //look for top layer that matches new rule
    for (var i = 0; i < filter.nodes.length; i++) {
      var node = filter.nodes[i];
      // case 1 - top level is a rule
      if ((node as FilterJsonRule).key === filterRule.key) {
        // create a new node
        var newNode: FilterJsonNode = {
          op: FilterOperator.OR,
          nodes: [node, filterRule],
          type: "node",
        };
        filter.nodes[i] = newNode;
        setFilter({ ...filter });
        return;
      }
      if ((node as FilterJsonRule).key === undefined) {
        //case 2 - top level is a node
        // check another layer in
        // node must have children
        var subNode = (node as FilterJsonNode).nodes[0];
        if ((subNode as FilterJsonRule).key === filterRule.key) {
          (node as FilterJsonNode).nodes.push(filterRule);
          setFilter({ ...filter });
          return;
        }
      }
    }
    // did not find existing key
    // create new key
    filter.nodes.push(filterRule);
    setFilter({ ...filter });
  };
  searchValue.addFilter = addFilter;

  useEffect(() => {
    return () => {
      // @ts-ignore
      searchValue.search.cancel();
    };
  }, []);

  useEffect(() => {
    async function init() {
      var startTime = new Date();
      // Parse data
      // get data async here
      var data = await fetch(
        "https://digimoncard.dev/data.json?t=" + Date.now(),
        {
          // headers: {
          //   "Cache-Control": "no-cache",
          // },
        }
      ).then((data) => data.json());

      //var baseCards = [...data.map(dataMapper)].filter(
      //  (d: DigimonData) => d.language == 231
      //);
      var baseCards = [...data]
        .filter((d) => d.language === "231")
        .map(dataMapper);
      // Remove duplicates
      var seen = new Map();
      var gbmap = new Map();
      var ciBiMap = new Map();
      if (true) {
        for (var i = 0; i < baseCards.length; i++) {
          var card = baseCards[i];
          var isParallel =
            card.pid || (card.notes && card.notes.includes("parallel"))
              ? "p"
              : "";

          var key = card.id + "_" + card.pid + "_" + isParallel;
          if (seen.has(key)) {
            if (seen.get(key).intl === true) {
              //ignore proxy
              continue;
            } else {
              seen.set(key, card);
            }
          }
          // doesnt exist
          seen.set(key, card);
          ciBiMap.set(card.id, []);
        }
        baseCards = Array.from(seen.values());
        for (var i = 0; i < baseCards.length; i++) {
          baseCards[i].baseid = i;
          gbmap.set(baseCards[i].gid, baseCards[i].baseid);
        }
        setGbMap(gbmap);
        var lcBaseCards = baseCards.map(dataMapperLowerCase);
        //searchValue.baseCards = baseCards;
        setBaseCards(baseCards);
        setLcBaseCards(lcBaseCards);
        //console.info(baseCards);
      }

      var finalTime = new Date();
      console.info(
        "Load time:",
        (finalTime.getTime() - startTime.getTime()) / 1000
      );

      startTime = new Date();
      var searcher = new FuzzySearch({
        source: baseCards,
        output_map: "root",
        bonus_match_start: 0.35,
        bonus_position_decay: 0.95,
        keys: [
          "name",
          "dtype",
          "form",
          "color",
          "attr",
          "meffect",
          "seceffect",
          "ieffect",
          "src",
          "id",
          "artist",
        ],
      });
      //var result = searcher.search("agu");

      var finalTime = new Date();
      console.info(
        "Load time:",
        (finalTime.getTime() - startTime.getTime()) / 1000
      );
      //   console.info(
      //     result.map(
      //       (ser: any) =>
      //         searcher.keys[ser.matchIndex] +
      //         " " +
      //         searcher.highlight(ser.item[searcher.keys[ser.matchIndex]])
      //     )
      //   );
      searchValue.searcher = searcher;
    }
    init();
  }, []);

  //idx.PUT();

  return (
    <SearchContext.Provider
      value={{
        ...searchValue,
        filter,
        results,
        baseCards,
        lcBaseCards,
        inputValue,
        setInputValue,
        gbMap,
        sortOrderAsc,
        setSortOrderAsc,
        sortBy,
        setSortBy,
        showResults,
        setShowResults,
        filterResults,
        setFilterResults,
        sourceData,
        setSourceData,
        ciBiMap,
        setCiBiMap,
        nameData,
        setNameData,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
