import { FunctionComponent, useContext } from "react";

import "./App.css";
// import { FirebaseContext } from "./firebase";
// import { selectAuth, signInAuth } from "./features/authStore/authSlice";
import { useAppDispatch, useAppSelector } from "./app/hooks";
// import {
//   getDecksOnline,
//   selectDecks,
//   setDecksOnline,
// } from "./features/deckStore/deckSlice";
// import Decks from "./features/deckStore/Decks";

import "./scss/style.scss";
import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  RouteComponentProps,
  Switch,
  Prompt,
} from "react-router-dom";

import { ContextMenu } from "./components/ContextMenu";
import { selectContext, setVisible } from "./features/contextMenu/contextSlice";
import { BuilderPortal } from "./components/BuilderPortal";
import { SearchContext } from "./provider/searchProvider";
import { setPosition } from "./features/deckBuilder/deckBuilderSlice";
import { selectCore, setCore } from "./features/coreuiStore/coreSlice";
import usePageTracking from "./components/GoogleAnalytics";
import {
  selectHelper,
  frontPageHelper,
  setHelper,
} from "./features/helperStore/helperSlice";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useCookies } from "react-cookie";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout: FunctionComponent<RouteComponentProps> = React.lazy(
  () => import("./layout/DefaultLayout")
);

//type Props = { component: FunctionComponent } & RouteComponentProps;

// Pages
const Login: FunctionComponent<RouteComponentProps> = React.lazy(
  () => import("./views/pages/login/Login")
);
const Register: FunctionComponent<RouteComponentProps> = React.lazy(
  () => import("./views/pages/register/Register")
);
const Page404: FunctionComponent<RouteComponentProps> = React.lazy(
  () => import("./views/pages/page404/Page404")
);
const Page500: FunctionComponent<RouteComponentProps> = React.lazy(
  () => import("./views/pages/page500/Page500")
);

const News: FunctionComponent<RouteComponentProps> = React.lazy(
  () => import("./views/pages/news/news")
);

// function App() {
//   const { api } = useContext(FirebaseContext);
//   const auth = useAppSelector(selectAuth);
//   const deckState = useAppSelector(selectDecks);

//   useEffect(() => {
//     if (api && api.getTodos != null) {
//       console.info("APIs");
//       //api.getTodos();
//       api.getTodos2();
//     }
//   }, [api]);

//   const dispatch = useAppDispatch();

//   return (
//     <div className="App">
//       <header className="App-header">
//         {auth.status}
//         {auth.status !== "signedIn" ? (
//           <>
//             <button
//               onClick={() => {
//                 dispatch(signInAuth());
//               }}
//             >
//               Google SignIn
//             </button>
//           </>
//         ) : (
//           <>
//             {auth.auth.email} {auth.auth.uid}
//             <button onClick={() => dispatch(getDecksOnline(auth.auth.uid))}>
//               Get Decks (Overwrite local copy)
//             </button>
//             <br />
//             <button
//               onClick={() =>
//                 setDecksOnline(auth.auth.uid, deckState.onlineDecks)
//               }
//             >
//               Upload Decks (Overwrite online)
//             </button>
//             <button onClick={api.goSignOut}>Sign Out </button>
//           </>
//         )}
//         {/* <img src={logo} className="App-logo" alt="logo" /> */}
//         <Decks />
//         <Counter />
//       </header>
//     </div>
//   );
// }

const GoogleAnalytics = () => {
  usePageTracking();
  return <></>;
};

const App: React.FC = () => {
  const { visible } = useAppSelector(selectContext);
  const { steps, stepIndex, run } = useAppSelector(selectHelper);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const escFunction: (this: Document, ev: KeyboardEvent) => any = (ev) => {
      if (visible && (ev.code == "Esc" || ev.code == "Escape")) {
        dispatch(setVisible(false));
      }
    };
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [visible]);

  //const { showResults, setShowResults } = useContext(SearchContext);
  const setShowResults: (val: any) => void = (val) => {};

  React.useEffect(() => {
    if (window.innerHeight > window.innerWidth) {
      dispatch(setPosition("bottom"));
    }
    if (window.innerWidth > 1366) {
      dispatch(setCore({ sidebarShow: true }));
    }
  }, []);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      dispatch(setHelper({ run: false }));
    }

    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();
  };

  const { theme: themeColor } = useAppSelector(selectCore);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode:
            themeColor === "light"
              ? "light"
              : themeColor === "dark"
              ? "dark"
              : prefersDarkMode
              ? "dark"
              : "light",
        },
      }),
    [prefersDarkMode, themeColor]
  );

  const [cookies, setCookie] = useCookies<string, { theme: string }>(["name"]);

  useEffect(() => {
    if (cookies.theme === undefined) {
      setCookie("theme", themeColor, {
        path: "/",
      });
    } else if (cookies.theme === "light") {
      dispatch(setCore({ theme: "light" }));
    }
  }, []);

  useEffect(() => {
    console.log("switching ");
    var element = document.getElementById("root");
    if (themeColor === "dark") {
      element!.classList.add("dark");
      setCookie("theme" as never, "dark", {
        path: "/",
        //domain: ".digimoncard.dev",
      });
    } else {
      element!.classList.remove("dark");
      setCookie("theme", "light", {
        path: "/",
        //domain: ".digimoncard.dev",
      });
    }
  }, [themeColor, theme]);

  console.log("cookie", cookies.theme);

  return (
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        console.log(message);
        console.log("cookie", cookies.theme);
        setShowResults(false);
        callback(true);
      }}
    >
      <ThemeProvider theme={theme}>
        <Prompt message="test" />
        <React.Suspense fallback={loading}>
          <ContextMenu />
          <BuilderPortal />
          <GoogleAnalytics />
          <Joyride
            continuous={true}
            run={run}
            steps={frontPageHelper}
            scrollToFirstStep={true}
            //stepIndex={0}
            callback={handleJoyrideCallback}
          />
          <Switch>
            <Route
              exact
              path="/login"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              render={(props) => <Page500 {...props} />}
            />
            <Route path="/" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
