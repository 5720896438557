import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Divider from "@mui/material/Divider";

import styles from "./BuilderPortal.module.css";
import { useAppSelector } from "src/app/hooks";
import { selectCore } from "src/features/coreuiStore/coreSlice";

export const OverflowTools: React.FC<{ items: JSX.Element[] }> = ({
  items,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { theme } = useAppSelector(selectCore);
  const dark = theme === "dark" ? "dark" : "";

  return (
    <>
      {items.length > 0 && (
        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          More
        </Button>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className={styles.menutools + " " + dark}
      >
        <MenuList className="buildGrid p-0" dense>
          {items.map((val, i) => (
            <React.Fragment key={i}>
              <Divider flexItem className="m-0" />
              <MenuItem className="p-0">{val}</MenuItem>
            </React.Fragment>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};
