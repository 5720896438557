import React from "react";
import { DigimonData } from "src/data/cardInfo";
import styles from "./CardImage.module.css";

export const CardImage: React.FC<{
  data: DigimonData;
  noZoom?: boolean;
  size?: number;
  onRightClick?: React.MouseEventHandler<HTMLDivElement>;
  onLeftClick?: React.MouseEventHandler<HTMLDivElement>;
  otherStyles?: React.CSSProperties;
}> = ({ data, noZoom, size, onRightClick, onLeftClick, otherStyles }) => {
  size = size || 1;
  return (
    <img
      loading="lazy"
      onContextMenu={onRightClick}
      onClick={onLeftClick}
      draggable={false}
      className={styles.cardImage + " " + (noZoom ? styles.noHover : "")}
      src={data.imageUrl}
      style={{
        width: `${size * 18}vmin`,
        height: `${(size * 18) / 0.71}vmin`,
        borderRadius: `${size * 1.5}vmin`,
        ...otherStyles,
      }}
      onLoad={(e) => {
        (e.target as HTMLImageElement).style.opacity = "1";
      }}
      onError={(e) =>
        // @ts-ignore
        ((e.target as HTMLImageElement).src =
          "https://assets.cardlist.dev/other/blank.png")
      }
    />
  );
};
